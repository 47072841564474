.control {
  margin: 1rem 0;
}

.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
}

.control input,
.control textarea {
  display: block;
  width: 100%;
  font: inherit;
  padding: 0.2rem;
  border-radius: 12px;
  border: 1px solid #ccc;
}

.control input:focus,
.control textarea:focus {
  outline: none;
  border-color: #230052;
}